import userManager from "@/services/userManager"
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: RouteConfig[] = [
  {
    path: '',
    redirect: "/app"
  },
  {
    path: '/login',
    component: () => import('../views/Login.vue'),
    beforeEnter(from, to, next) {
      const userInfo = userManager.getUserInfo()
      if (userInfo) {
        next("/app")
      } else {
        next()
      }
    },
  },
  {
    path: '/app',
    component: () => import('../views/app/Layout.vue'),
    beforeEnter(from, to, next) {
      const userInfo = userManager.getUserInfo()
      if (!userInfo) {
        next("/login")
      } else {
        next()
      }
    },
    children: [
      {
        path: "",
        redirect: "orders"
      },
      {
        path: "action_logs",
        component: () => import('../views/app/ActionLogs.vue'),
      },
      {
        path: "change_password",
        component: () => import('../views/app/ChangePassword.vue'),
      },
      {
        path: "fulfillment",
        component: () => import('../views/app/Fulfillment.vue'),
        children: [
          {
            path: "",
            redirect: "print_barcode"
          },
          {
            path: "print_barcode",
            component: () => import('../views/app/fulfillment/PrintBarcode.vue'),
          },
          {
            path: "print_main",
            component: () => import('../views/app/fulfillment/PrintMain.vue'),
          },
          {
            path: "print_design",
            component: () => import('../views/app/fulfillment/PrintDesign.vue'),
          },
          {
            path: "confirm_print_main",
            component: () => import('../views/app/fulfillment/ConfirmPrintMain.vue'),
          },
          {
            path: "set_quality_check_failed",
            component: () => import('../views/app/fulfillment/SetQualityCheckFailed.vue'),
          },
          {
            path: "package",
            component: () => import('../views/app/fulfillment/Package.vue'),
          },
        ]
      },
      {
        path: "orders",
        component: () => import('../views/app/Orders.vue'),
        children: [
          {
            path: "",
            redirect: "all"
          },
          {
            path: "all",
            component: () => import('../views/app/orders/AllOrders.vue'),
          },
          {
            path: "open",
            component: () => import('../views/app/orders/OpenOrders.vue'),
          },
          {
            path: "processing",
            component: () => import('../views/app/orders/ProcessingOrders.vue'),
          },
          {
            path: "shipping",
            component: () => import('../views/app/orders/ShippingOrders.vue'),
          },
          {
            path: "completed",
            component: () => import('../views/app/orders/CompletedOrders.vue'),
          },
          {
            path: "cancelled",
            component: () => import('../views/app/orders/CancelledOrders.vue'),
          },
        ]
      },
      {
        path: "product_imports",
        component: () => import('../views/app/ProductImports.vue'),
      },
      {
        path: "products",
        component: () => import('../views/app/Products.vue'),
      },
      {
        path: "products/:productId/product_variants",
        component: () => import('../views/app/ProductVariants.vue'),
      },
      {
        path: "product_variants",
        component: () => import('../views/app/ProductVariants.vue'),
      },
      {
        path: "setting",
        component: () => import('../views/app/Setting.vue'),
      },
      {
        path: "shipping_vendors",
        component: () => import('../views/app/ShippingVendors.vue'),
      },
      {
        path: "stores",
        component: () => import('../views/app/Stores.vue'),
      },
      {
        path: "users",
        component: () => import('../views/app/Users.vue'),
      },
      {
        path: 'inventory-stock-in',
        component: () => import('../views/app/inventory/StockIn.vue'),
      },
      {
        path: "inventory-stock-in/history",
        component: () => import('../views/app/inventory/StockInHistory.vue'),
      },
      {
        path: 'inventory-stock-out',
        component: () => import('../views/app/inventory/StockOut.vue'),
      },
      {
        path: 'inventory-stock-out/history',
        component: () => import('../views/app/inventory/StockOutHistory.vue'),
      },
      {
        path: "inventory-stock-available",
        component: () => import('../views/app/inventory/StockAvailable.vue'),
      },
    ]
  },
]

const router = new VueRouter({
  base: "/",
  routes
})

export default router
